var InitialTechKeywords = [
  { text: "javascript", size: 1426 },
  { text: "php", size: 1373 },
  { text: "java", size: 1142 },
  { text: "android", size: 1122 },
  { text: "sql", size: 886 },
  { text: "python", size: 726 },
  { text: "mysql", size: 649 },
  { text: "jquery", size: 598 },
  { text: "react", size: 489 },
  { text: "ios", size: 467 },
  { text: "angular", size: 446 },
  { text: "git", size: 436 },
  { text: "css javascript", size: 425 },
  { text: "object", size: 418 },
  { text: "object oriented", size: 375 },
  { text: "html css javascript", size: 375 },
  { text: "aws", size: 324 },
  { text: "rest", size: 315 },
  { text: "sql server", size: 304 },
  { text: "linux", size: 297 },
  { text: "xml", size: 251 },
  { text: "javascript jquery", size: 233 },
  { text: "php mysql", size: 199 },
  { text: "azure", size: 196 },
  { text: "spring", size: 194 },
  { text: "monitoring", size: 167 },
  { text: "object oriented programming", size: 160 },
  { text: "mongodb", size: 157 },
  { text: "django", size: 154 },
  { text: "ci", size: 139 },
  { text: "react js", size: 129 },
  { text: "oracle", size: 129 },
  { text: "knowledge php", size: 126 },
  { text: "cd", size: 126 },
  { text: "android sdk", size: 124 },
  { text: "ci cd", size: 123 },
  { text: "core java", size: 122 },
  { text: "java script", size: 122 },
  { text: "php developer", size: 122 },
  { text: "javascript html", size: 119 },
  { text: "angular js", size: 113 },
  { text: "nosql", size: 107 },
  { text: "docker", size: 107 },
  { text: "react native", size: 105 },
  { text: "kubernetes", size: 104 },
  { text: "css javascript jquery", size: 103 },
  { text: "knowledge android", size: 97 },
  { text: "html css javascript jquery", size: 96 },
  { text: "html javascript", size: 96 },
  { text: "experience android", size: 96 },
  { text: "android ios", size: 93 },
  { text: "soap", size: 93 },
  { text: "core php", size: 88 },
  { text: "tools git", size: 88 },
  { text: "hibernate", size: 87 },
  { text: "android developer", size: 86 },
  { text: "javascript html css", size: 82 },
  { text: "experience php", size: 78 },
  { text: "javascript css", size: 76 },
  { text: "knowledge object", size: 74 },
  { text: "javascript ajax", size: 72 },
  { text: "versioning tools git", size: 72 },
  { text: "android applications", size: 71 },
  { text: "knowledge sql", size: 70 },
  { text: "knowledge object oriented", size: 69 },
  { text: "ms sql", size: 68 },
  { text: "code versioning tools git", size: 68 },
  { text: "applications android", size: 67 },
  { text: "jquery ajax", size: 66 },
  { text: "javascript frameworks", size: 65 },
  { text: "android studio", size: 62 },
  { text: "sql queries", size: 60 },
  { text: "spring boot", size: 60 },
  { text: "knowledge java", size: 60 },
  { text: "ios android", size: 60 },
  { text: "php programming", size: 59 },
  { text: "aws azure", size: 59 },
  { text: "understanding object", size: 59 },
  { text: "rest apis", size: 58 },
  { text: "understanding object oriented", size: 58 },
  { text: "knowledge javascript", size: 57 },
  { text: "php html", size: 56 },
  { text: "rest api", size: 56 },
  { text: "android platform", size: 56 },
  { text: "object oriented design", size: 55 },
  { text: "git svn", size: 54 },
  { text: "gcp", size: 54 },
  { text: "php web", size: 54 },
  { text: "using php", size: 54 },
  { text: "systems git", size: 53 },
  { text: "java jee", size: 53 },
  { text: "android app", size: 53 },
  { text: "json xml", size: 52 },
  { text: "jsp", size: 51 },
  { text: "java c", size: 51 },
  { text: "java developer", size: 51 },
  { text: "java python", size: 50 },
  { text: "technologies javascript", size: 50 },
  { text: "control systems git", size: 48 },
  { text: "c java", size: 46 },
  { text: "css jquery", size: 46 },
];

const TechKeywords = InitialTechKeywords.slice(0, 40);

var InitialConceptKeywords = [
  { text: "development", size: 4234 },
  { text: "software", size: 3356 },
  { text: "design", size: 2987 },
  { text: "data", size: 1682 },
  { text: "requirements", size: 1627 },
  { text: "testing", size: 1381 },
  { text: "developer", size: 1250 },
  { text: "develop", size: 1156 },
  { text: "required", size: 1128 },
  { text: "performance", size: 1099 },
  { text: "test", size: 1068 },
  { text: "services", size: 945 },
  { text: "developing", size: 941 },
  { text: "developers", size: 779 },
  { text: "software development", size: 747 },
  { text: "practices", size: 741 },
  { text: "maintain", size: 724 },
  { text: "database", size: 720 },
  { text: "related", size: 710 },
  { text: "frameworks", size: 642 },
  { text: "framework", size: 641 },
  { text: "apis", size: 632 },
  { text: "integration", size: 576 },
  { text: "designing", size: 551 },
  { text: "oriented", size: 547 },
  { text: "analytical", size: 543 },
  { text: "concepts", size: 523 },
  { text: "platform", size: 503 },
  { text: "cloud", size: 502 },
  { text: "architecture", size: 495 },
  { text: "databases", size: 457 },
  { text: "api", size: 454 },
  { text: "mvc", size: 453 },
  { text: "security", size: 423 },
  { text: "object", size: 418 },
  { text: "debugging", size: 413 },
  { text: "standards", size: 412 },
  { text: "object oriented", size: 375 },
  { text: "agile", size: 368 },
  { text: "unit", size: 359 },
  { text: "platforms", size: 350 },
  { text: "maintaining", size: 334 },
  { text: "life", size: 331 },
  { text: "automation", size: 330 },
  { text: "principles", size: 306 },
  { text: "web development", size: 298 },
  { text: "web services", size: 298 },
  { text: "deployment", size: 296 },
  { text: "cycle", size: 293 },
  { text: "service", size: 288 },
  { text: "patterns", size: 287 },
  { text: "troubleshooting", size: 283 },
  { text: "perform", size: 280 },
  { text: "scalable", size: 269 },
  { text: "designs", size: 263 },
  { text: "optimization", size: 261 },
  { text: "native", size: 255 },
  { text: "life cycle", size: 253 },
  { text: "application development", size: 249 },
  { text: "continuous", size: 249 },
  { text: "design develop", size: 247 },
  { text: "oops", size: 246 },
  { text: "troubleshoot", size: 242 },
  { text: "infrastructure", size: 241 },
  { text: "tests", size: 234 },
  { text: "designers", size: 232 },
  { text: "related field", size: 230 },
  { text: "requirement", size: 229 },
  { text: "methodologies", size: 222 },
  { text: "algorithms", size: 218 },
  { text: "development experience", size: 218 },
  { text: "debug", size: 210 },
  { text: "design development", size: 207 },
  { text: "software engineering", size: 207 },
  { text: "devops", size: 207 },
  { text: "skills required", size: 205 },
  { text: "enhance", size: 205 },
  { text: "development team", size: 204 },
  { text: "analytical skills", size: 202 },
  { text: "development life", size: 198 },
  { text: "development life cycle", size: 196 },
  { text: "experience developing", size: 192 },
  { text: "reusable", size: 190 },
  { text: "optimize", size: 178 },
  { text: "relational", size: 178 },
  { text: "integrate", size: 177 },
  { text: "design patterns", size: 168 },
  { text: "design build", size: 166 },
  { text: "standard", size: 163 },
  { text: "oriented programming", size: 161 },
  { text: "object oriented programming", size: 160 },
  { text: "structures", size: 158 },
  { text: "integrating", size: 157 },
  { text: "software engineer", size: 155 },
  { text: "design principles", size: 152 },
  { text: "analytics", size: 152 },
  { text: "test cases", size: 151 },
  { text: "deploy", size: 150 },
  { text: "designing developing", size: 149 },
  { text: "concept", size: 143 },
  { text: "data structures", size: 143 },
];

const ConceptKeywords = InitialConceptKeywords.slice(0, 40);

var InitialSoftSkillsKeywords = [
  { text: "understanding", size: 1863 },
  { text: "communication", size: 1473 },
  { text: "ensure", size: 894 },
  { text: "management", size: 869 },
  { text: "engineering", size: 860 },
  { text: "solving", size: 836 },
  { text: "communication skills", size: 817 },
  { text: "learn", size: 796 },
  { text: "problem solving", size: 781 },
  { text: "maintain", size: 724 },
  { text: "collaborate", size: 649 },
  { text: "implement", size: 583 },
  { text: "learning", size: 577 },
  { text: "write", size: 487 },
  { text: "understand", size: 476 },
  { text: "writing", size: 474 },
  { text: "implementation", size: 401 },
  { text: "engineer", size: 387 },
  { text: "solving skills", size: 351 },
  { text: "problem solving skills", size: 350 },
  { text: "contribute", size: 334 },
  { text: "maintaining", size: 334 },
  { text: "participate", size: 330 },
  { text: "identify", size: 329 },
  { text: "ensuring", size: 328 },
  { text: "engineers", size: 314 },
  { text: "manage", size: 311 },
  { text: "implementing", size: 288 },
  { text: "independently", size: 269 },
  { text: "excellent communication", size: 258 },
  { text: "passion", size: 257 },
  { text: "deliver", size: 252 },
  { text: "collaboration", size: 211 },
  { text: "resolve", size: 209 },
  { text: "software engineering", size: 207 },
  { text: "managers", size: 207 },
  { text: "communicate", size: 195 },
  { text: "passionate", size: 177 },
  { text: "written communication", size: 167 },
  { text: "managing", size: 166 },
  { text: "basic understanding", size: 162 },
  { text: "software engineer", size: 155 },
  { text: "collaborating", size: 146 },
  { text: "work independently", size: 144 },
  { text: "solid understanding", size: 140 },
  { text: "collaborative", size: 139 },
  { text: "written communication skills", size: 138 },
  { text: "collaborate cross", size: 138 },
  { text: "translate", size: 138 },
  { text: "solve", size: 136 },
  { text: "engineering related", size: 134 },
  { text: "excellent communication skills", size: 133 },
  { text: "collaborate cross functional", size: 133 },
  { text: "manager", size: 132 },
  { text: "presentation", size: 131 },
  { text: "collaborate cross functional teams", size: 129 },
  { text: "identifying", size: 128 },
  { text: "write clean", size: 126 },
  { text: "analytical problem solving", size: 123 },
  { text: "machine learning", size: 123 },
  { text: "thinking", size: 121 },
  { text: "develop maintain", size: 120 },
  { text: "proficient understanding", size: 112 },
  { text: "grow", size: 112 },
  { text: "maintainable", size: 108 },
  { text: "science engineering", size: 107 },
  { text: "verbal communication", size: 106 },
  { text: "computer science engineering", size: 106 },
  { text: "drive", size: 105 },
  { text: "delivering", size: 102 },
  { text: "engine", size: 100 },
  { text: "verbal written communication", size: 97 },
  { text: "learn technologies", size: 97 },
  { text: "management skills", size: 97 },
  { text: "passionate about", size: 94 },
  { text: "written verbal communication", size: 92 },
  { text: "analytical problem solving skills", size: 91 },
  { text: "ability learn", size: 89 },
  { text: "demonstrated", size: 89 },
  { text: "project management", size: 89 },
  { text: "excellent problem solving", size: 87 },
  { text: "product managers", size: 87 },
  { text: "ability work independently", size: 86 },
  { text: "proactive", size: 82 },
  { text: "participating", size: 82 },
  { text: "solving analytical", size: 82 },
  { text: "problem solving analytical", size: 82 },
  { text: "time management", size: 81 },
  { text: "mentor", size: 80 },
  { text: "design implement", size: 80 },
  { text: "ownership", size: 77 },
  { text: "participate code", size: 77 },
  { text: "engineering related field", size: 77 },
  { text: "verbal written communication skills", size: 76 },
  { text: "participate code reviews", size: 76 },
  { text: "resolving", size: 76 },
  { text: "demonstrate", size: 76 },
  { text: "verbal communication skills", size: 75 },
  { text: "understanding front", size: 75 },
  { text: "understanding code", size: 74 },
  { text: "understanding front end", size: 74 },
];

const SoftSkillsKeywords = InitialSoftSkillsKeywords.slice(0, 40);

var InitialMiscKeywords = [
  { text: "experience", size: 5509 },
  { text: "knowledge", size: 4308 },
  { text: "skills", size: 4224 },
  { text: "work", size: 2869 },
  { text: "web", size: 2532 },
  { text: "team", size: 2375 },
  { text: "code", size: 2151 },
  { text: "applications", size: 2130 },
  { text: "technologies", size: 1618 },
  { text: "application", size: 1555 },
  { text: "programming", size: 1531 },
  { text: "working", size: 1517 },
  { text: "ability", size: 1496 },
  { text: "technical", size: 1480 },
  { text: "html", size: 1452 },
  { text: "end", size: 1405 },
  { text: "using", size: 1327 },
  { text: "css", size: 1326 },
  { text: "c", size: 1233 },
  { text: "must", size: 1202 },
  { text: "job", size: 1116 },
  { text: "tools", size: 1116 },
  { text: "excellent", size: 1115 },
  { text: "net", size: 1102 },
  { text: "quality", size: 1085 },
  { text: "responsibilities", size: 1055 },
  { text: "project", size: 1055 },
  { text: "systems", size: 1002 },
  { text: "computer", size: 995 },
  { text: "other", size: 991 },
  { text: "candidate", size: 990 },
  { text: "solutions", size: 990 },
  { text: "your", size: 960 },
  { text: "years", size: 938 },
  { text: "html css", size: 932 },
  { text: "b", size: 925 },
  { text: "product", size: 919 },
  { text: "problem", size: 911 },
  { text: "projects", size: 894 },
  { text: "any", size: 891 },
  { text: "mobile", size: 869 },
  { text: "user", size: 868 },
  { text: "business", size: 840 },
  { text: "support", size: 839 },
  { text: "tech", size: 833 },
  { text: "including", size: 807 },
  { text: "science", size: 780 },
  { text: "build", size: 775 },
  { text: "all", size: 762 },
  { text: "issues", size: 753 },
  { text: "front", size: 735 },
  { text: "e", size: 719 },
  { text: "degree", size: 718 },
  { text: "server", size: 717 },
  { text: "based", size: 716 },
  { text: "front end", size: 710 },
  { text: "js", size: 707 },
  { text: "functional", size: 706 },
  { text: "basic", size: 702 },
  { text: "computer science", size: 700 },
  { text: "looking", size: 699 },
  { text: "high", size: 685 },
  { text: "technology", size: 678 },
  { text: "environment", size: 675 },
  { text: "teams", size: 669 },
  { text: "can", size: 661 },
  { text: "role", size: 657 },
  { text: "time", size: 656 },
  { text: "familiarity", size: 652 },
  { text: "system", size: 651 },
  { text: "well", size: 649 },
  { text: "responsible", size: 615 },
  { text: "coding", size: 606 },
  { text: "s", size: 584 },
  { text: "features", size: 575 },
  { text: "building", size: 566 },
  { text: "provide", size: 547 },
  { text: "create", size: 547 },
  { text: "mca", size: 545 },
  { text: "able", size: 544 },
  { text: "core", size: 537 },
  { text: "#", size: 537 },
  { text: "hands", size: 537 },
  { text: "documentation", size: 530 },
  { text: "client", size: 524 },
  { text: "wordpress", size: 520 },
  { text: "written", size: 519 },
  { text: "preferred", size: 514 },
  { text: "industry", size: 513 },
  { text: "description", size: 512 },
  { text: "b tech", size: 508 },
  { text: "products", size: 507 },
  { text: "c #", size: 504 },
  { text: "their", size: 497 },
  { text: "training", size: 496 },
  { text: "analysis", size: 490 },
  { text: "website", size: 482 },
  { text: "app", size: 478 },
  { text: "existing", size: 477 },
  { text: "back", size: 466 },
  { text: "cross", size: 463 },
];

const MiscKeywords = InitialMiscKeywords.slice(0, 40);

const AllFreshersKeywords = {
  TechKeywords,
  ConceptKeywords,
  SoftSkillsKeywords,
  MiscKeywords,
};

export default AllFreshersKeywords;
