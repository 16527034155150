import './App.css';
// import Barchart from './components/Barchart';
import KeywordsSidebar from './components/KeywordsSidebar';

function App() {
  return (
    <div className="App">
      {/* <h1>Hello React + D3 world!</h1> */}
      {/* <Barchart/> */}

      <KeywordsSidebar />
    </div>
  );
}

export default App;