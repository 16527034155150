var InitialTechKeywords = [
  { text: "sql", size: 403 },
  { text: "ios", size: 274 },
  { text: "azure", size: 242 },
  { text: "linux", size: 236 },
  { text: "sql server", size: 198 },
  { text: "javascript", size: 191 },
  { text: "angular", size: 189 },
  { text: "git", size: 185 },
  { text: "object", size: 133 },
  { text: "object oriented", size: 121 },
  { text: "rest", size: 110 },
  { text: "react", size: 102 },
  { text: "android", size: 100 },
  { text: "aws", size: 91 },
  { text: "jquery", size: 87 },
  { text: "java", size: 85 },
  { text: "object oriented programming", size: 80 },
  { text: "ci", size: 68 },
  { text: "cd", size: 68 },
  { text: "ci cd", size: 68 },
  { text: "xml", size: 68 },
  { text: "python", size: 65 },
  { text: "ios frameworks", size: 51 },
  { text: "understanding object", size: 47 },
  { text: "understanding object oriented", size: 47 },
  { text: "ms sql", size: 45 },
  { text: "css javascript", size: 41 },
  { text: "javascript jquery", size: 41 },
  { text: "html css javascript", size: 40 },
  { text: "mysql", size: 39 },
  { text: "experience azure", size: 39 },
  { text: "understanding object oriented programming", size: 38 },
  { text: "experience sql", size: 38 },
  { text: "experience linux", size: 37 },
  { text: "docker", size: 36 },
  { text: "nosql", size: 36 },
  { text: "knowledge ios", size: 35 },
  { text: "microsoft sql", size: 34 },
  { text: "microsoft sql server", size: 34 },
  { text: "kubernetes", size: 34 },
  { text: "azure aws", size: 33 },
  { text: "experience ios", size: 33 },
  { text: "azure devops", size: 32 },
  { text: "ios app", size: 32 },
  { text: "embedded linux", size: 31 },
  { text: "rest api", size: 30 },
  { text: "ms sql server", size: 30 },
  { text: "tools git", size: 30 },
  { text: "rest apis", size: 29 },
  { text: "ios frameworks core", size: 29 },
  { text: "ios frameworks core data", size: 29 },
  { text: "linux kernel", size: 26 },
  { text: "php", size: 25 },
  { text: "react js", size: 24 },
  { text: "microsoft azure", size: 23 },
  { text: "experience sql server", size: 23 },
  { text: "experience android", size: 23 },
  { text: "oracle", size: 22 },
  { text: "ios applications", size: 22 },
  { text: "sql queries", size: 20 },
  { text: "orm", size: 20 },
  { text: "gcp", size: 20 },
  { text: "git svn", size: 20 },
  { text: "angular react", size: 19 },
  { text: "ios platform", size: 19 },
  { text: "core microsoft sql", size: 19 },
  { text: "sql server modern", size: 19 },
  { text: "nunit understanding object", size: 19 },
  { text: "ef core microsoft sql", size: 19 },
  { text: "core microsoft sql server", size: 19 },
  { text: "microsoft sql server modern", size: 19 },
  { text: "sql server modern web", size: 19 },
  { text: "unit nunit understanding object", size: 19 },
  { text: "nunit understanding object oriented", size: 19 },
  { text: "object oriented programming familiar", size: 19 },
  { text: "javascript html", size: 19 },
  { text: "systems git", size: 18 },
  { text: "services experience azure", size: 18 },
  { text: "restful services experience azure", size: 18 },
  { text: "soap", size: 18 },
  { text: "ios development", size: 17 },
  { text: "nosql databases", size: 17 },
  { text: "aws azure", size: 17 },
  { text: "ios app development", size: 17 },
  { text: "css javascript jquery", size: 16 },
  { text: "mongodb", size: 16 },
  { text: "versioning tools git", size: 16 },
  { text: "code versioning tools git", size: 16 },
  { text: "databases sql", size: 15 },
  { text: "object oriented design", size: 15 },
  { text: "control systems git", size: 15 },
  { text: "html css javascript jquery", size: 15 },
  { text: "react native", size: 15 },
  { text: "monitoring", size: 15 },
  { text: "javascript frameworks", size: 14 },
  { text: "http", size: 14 },
  { text: "working linux", size: 14 },
  { text: "using git", size: 14 },
  { text: "connect ios", size: 14 },
  { text: "proficient angular", size: 14 },
  { text: "c linux", size: 14 },
];

const TechKeywords = InitialTechKeywords.slice(0, 40);

var InitialConceptKeywords = [
  { text: "development", size: 1324 },
  { text: "design", size: 1006 },
  { text: "software", size: 869 },
  { text: "testing", size: 372 },
  { text: "performance", size: 335 },
  { text: "framework", size: 322 },
  { text: "data", size: 318 },
  { text: "services", size: 294 },
  { text: "develop", size: 292 },
  { text: "architecture", size: 283 },
  { text: "requirements", size: 257 },
  { text: "agile", size: 255 },
  { text: "test", size: 250 },
  { text: "api", size: 244 },
  { text: "software development", size: 244 },
  { text: "integration", size: 241 },
  { text: "patterns", size: 235 },
  { text: "unit", size: 230 },
  { text: "developing", size: 229 },
  { text: "practices", size: 220 },
  { text: "maintain", size: 218 },
  { text: "frameworks", size: 206 },
  { text: "mvc", size: 199 },
  { text: "concepts", size: 195 },
  { text: "related", size: 195 },
  { text: "debugging", size: 187 },
  { text: "oriented", size: 179 },
  { text: "cloud", size: 178 },
  { text: "design patterns", size: 167 },
  { text: "apis", size: 166 },
  { text: "principles", size: 164 },
  { text: "required", size: 157 },
  { text: "standards", size: 145 },
  { text: "database", size: 145 },
  { text: "platform", size: 139 },
  { text: "object", size: 133 },
  { text: "designing", size: 126 },
  { text: "object oriented", size: 121 },
  { text: "development experience", size: 120 },
  { text: "web api", size: 117 },
  { text: "analytical", size: 112 },
  { text: "experience developing", size: 110 },
  { text: "developer", size: 110 },
  { text: "platforms", size: 109 },
  { text: "methodologies", size: 106 },
  { text: "entity framework", size: 106 },
  { text: "databases", size: 104 },
  { text: "security", size: 103 },
  { text: "tests", size: 96 },
  { text: "algorithms", size: 94 },
  { text: "scrum", size: 92 },
  { text: "scalable", size: 89 },
  { text: "net mvc", size: 87 },
  { text: "deployment", size: 85 },
  { text: "automation", size: 84 },
  { text: "net framework", size: 84 },
  { text: "oops", size: 84 },
  { text: "oriented programming", size: 81 },
  { text: "asp net mvc", size: 81 },
  { text: "related field", size: 80 },
  { text: "object oriented programming", size: 80 },
  { text: "perform", size: 80 },
  { text: "debug", size: 79 },
  { text: "development using", size: 78 },
  { text: "design develop", size: 78 },
  { text: "unit testing", size: 76 },
  { text: "objective", size: 76 },
  { text: "life", size: 75 },
  { text: "cycle", size: 75 },
  { text: "design development", size: 75 },
  { text: "objective c", size: 74 },
  { text: "troubleshooting", size: 73 },
  { text: "web services", size: 72 },
  { text: "reusable", size: 72 },
  { text: "developers", size: 70 },
  { text: "application development", size: 70 },
  { text: "devops", size: 69 },
  { text: "life cycle", size: 68 },
  { text: "maintainable", size: 68 },
  { text: "ci", size: 68 },
  { text: "cd", size: 68 },
  { text: "ci cd", size: 68 },
  { text: "service", size: 68 },
  { text: "continuous", size: 66 },
  { text: "web development", size: 66 },
  { text: "structures", size: 65 },
  { text: "standard", size: 64 },
  { text: "designs", size: 63 },
  { text: "data structures", size: 62 },
  { text: "agile development", size: 59 },
  { text: "development life", size: 59 },
  { text: "development life cycle", size: 59 },
  { text: "performance quality", size: 59 },
  { text: "design principles", size: 59 },
  { text: "architectural", size: 58 },
  { text: "optimization", size: 56 },
  { text: "sdlc", size: 55 },
  { text: "relational", size: 55 },
  { text: "software engineering", size: 55 },
  { text: "troubleshoot", size: 55 },
  { text: "threading", size: 55 },
];

const ConceptKeywords = InitialConceptKeywords.slice(0, 40);

var InitialSoftSkillsKeywords = [
  { text: "understanding", size: 618 },
  { text: "communication", size: 350 },
  { text: "management", size: 245 },
  { text: "engineering", size: 243 },
  { text: "ensure", size: 236 },
  { text: "maintain", size: 218 },
  { text: "solving", size: 191 },
  { text: "communication skills", size: 176 },
  { text: "problem solving", size: 175 },
  { text: "writing", size: 161 },
  { text: "implement", size: 155 },
  { text: "collaborate", size: 154 },
  { text: "write", size: 136 },
  { text: "identify", size: 103 },
  { text: "implementation", size: 102 },
  { text: "engineers", size: 101 },
  { text: "solving skills", size: 101 },
  { text: "problem solving skills", size: 100 },
  { text: "understand", size: 94 },
  { text: "participate", size: 87 },
  { text: "implementing", size: 76 },
  { text: "maintainable", size: 68 },
  { text: "ensuring", size: 62 },
  { text: "resolve", size: 61 },
  { text: "independently", size: 60 },
  { text: "deliver", size: 56 },
  { text: "software engineering", size: 55 },
  { text: "learn", size: 53 },
  { text: "contribute", size: 51 },
  { text: "mentor", size: 50 },
  { text: "excellent communication", size: 50 },
  { text: "solid understanding", size: 49 },
  { text: "learning", size: 48 },
  { text: "understanding object", size: 47 },
  { text: "understanding object oriented", size: 47 },
  { text: "develop maintain", size: 47 },
  { text: "written communication", size: 47 },
  { text: "translate", size: 47 },
  { text: "design implement", size: 47 },
  { text: "maintaining", size: 46 },
  { text: "collaboration", size: 46 },
  { text: "build maintain", size: 46 },
  { text: "communicate", size: 45 },
  { text: "write clean", size: 45 },
  { text: "engineering related", size: 44 },
  { text: "maintain code", size: 43 },
  { text: "maintainable code", size: 43 },
  { text: "written communication skills", size: 42 },
  { text: "verbal communication", size: 42 },
  { text: "written verbal communication", size: 41 },
  { text: "solve", size: 40 },
  { text: "analytical problem solving", size: 39 },
  { text: "manage", size: 39 },
  { text: "work independently", size: 39 },
  { text: "verbal written communication", size: 39 },
  { text: "understanding object oriented programming", size: 38 },
  { text: "maintain efficient", size: 37 },
  { text: "design build maintain", size: 37 },
  { text: "engineering related field", size: 36 },
  { text: "build maintain efficient", size: 36 },
  { text: "analytical problem solving skills", size: 35 },
  { text: "collaborating", size: 35 },
  { text: "verbal written communication skills", size: 35 },
  { text: "deep understanding", size: 35 },
  { text: "excellent problem solving", size: 35 },
  { text: "design build maintain efficient", size: 35 },
  { text: "ownership", size: 34 },
  { text: "manager", size: 34 },
  { text: "understanding software", size: 34 },
  { text: "managers", size: 34 },
  { text: "identify bottlenecks", size: 34 },
  { text: "maintain code quality", size: 34 },
  { text: "excellent written verbal communication", size: 33 },
  { text: "design implementation", size: 33 },
  { text: "science engineering", size: 32 },
  { text: "computer science engineering", size: 32 },
  { text: "collaborate cross", size: 32 },
  { text: "collaborate cross functional", size: 32 },
  { text: "engine", size: 32 },
  { text: "drive", size: 32 },
  { text: "collaborate cross functional teams", size: 31 },
  { text: "experience writing", size: 31 },
  { text: "ensure possible", size: 31 },
  { text: "ensure possible performance", size: 31 },
  { text: "help maintain", size: 30 },
  { text: "maintain efficient reusable", size: 30 },
  { text: "help maintain code", size: 30 },
  { text: "build maintain efficient reusable", size: 30 },
  { text: "help maintain code quality", size: 30 },
  { text: "communication protocols", size: 29 },
  { text: "verbal communication skills", size: 29 },
  { text: "written verbal communication skills", size: 29 },
  { text: "identify bottlenecks bugs", size: 29 },
  { text: "maintain code quality organization", size: 29 },
  { text: "ability work independently", size: 28 },
  { text: "code ensure", size: 28 },
  { text: "identifying", size: 28 },
  { text: "memory management", size: 28 },
  { text: "ensure possible performance quality", size: 28 },
  { text: "maintain efficient reusable reliable", size: 28 },
  { text: "computer engineering", size: 27 },
];

const SoftSkillsKeywords = InitialSoftSkillsKeywords.slice(0, 40);

var InitialMiscKeywords = [
  { text: "experience", size: 2755 },
  { text: "development", size: 1324 },
  { text: "c", size: 1110 },
  { text: "design", size: 1006 },
  { text: "knowledge", size: 962 },
  { text: "software", size: 869 },
  { text: "net", size: 846 },
  { text: "skills", size: 747 },
  { text: "code", size: 672 },
  { text: "understanding", size: 618 },
  { text: "years", size: 555 },
  { text: "applications", size: 519 },
  { text: "team", size: 516 },
  { text: "using", size: 493 },
  { text: "web", size: 489 },
  { text: "working", size: 474 },
  { text: "programming", size: 463 },
  { text: "#", size: 446 },
  { text: "core", size: 436 },
  { text: "c #", size: 424 },
  { text: "work", size: 423 },
  { text: "systems", size: 413 },
  { text: "sql", size: 403 },
  { text: "application", size: 400 },
  { text: "testing", size: 372 },
  { text: "tools", size: 356 },
  { text: "ability", size: 353 },
  { text: "communication", size: 350 },
  { text: "quality", size: 342 },
  { text: "technologies", size: 336 },
  { text: "technical", size: 336 },
  { text: "performance", size: 335 },
  { text: "framework", size: 322 },
  { text: "data", size: 318 },
  { text: "services", size: 294 },
  { text: "develop", size: 292 },
  { text: "'s", size: 291 },
  { text: "embedded", size: 285 },
  { text: "architecture", size: 283 },
  { text: "ios", size: 274 },
  { text: "computer", size: 265 },
  { text: "excellent", size: 260 },
  { text: "system", size: 258 },
  { text: "requirements", size: 257 },
  { text: "agile", size: 255 },
  { text: "server", size: 253 },
  { text: "test", size: 250 },
  { text: "other", size: 249 },
  { text: "asp", size: 249 },
  { text: "hands", size: 248 },
  { text: "must", size: 247 },
  { text: "management", size: 245 },
  { text: "familiarity", size: 245 },
  { text: "api", size: 244 },
  { text: "software development", size: 244 },
  { text: "product", size: 243 },
  { text: "engineering", size: 243 },
  { text: "azure", size: 242 },
  { text: "integration", size: 241 },
  { text: "asp net", size: 240 },
  { text: "ensure", size: 236 },
  { text: "linux", size: 236 },
  { text: "patterns", size: 235 },
  { text: "net core", size: 235 },
  { text: "years experience", size: 234 },
  { text: "unit", size: 230 },
  { text: "solutions", size: 230 },
  { text: "developing", size: 229 },
  { text: "science", size: 223 },
  { text: "practices", size: 220 },
  { text: "maintain", size: 218 },
  { text: "computer science", size: 216 },
  { text: "problem", size: 212 },
  { text: "including", size: 212 },
  { text: "frameworks", size: 206 },
  { text: "issues", size: 205 },
  { text: "degree", size: 203 },
  { text: "support", size: 203 },
  { text: "mvc", size: 199 },
  { text: "sql server", size: 198 },
  { text: "teams", size: 198 },
  { text: "plus", size: 197 },
  { text: "based", size: 195 },
  { text: "concepts", size: 195 },
  { text: "related", size: 195 },
  { text: "experience working", size: 195 },
  { text: "solving", size: 191 },
  { text: "javascript", size: 191 },
  { text: "end", size: 190 },
  { text: "angular", size: 189 },
  { text: "high", size: 187 },
  { text: "debugging", size: 187 },
  { text: "build", size: 187 },
  { text: "git", size: 185 },
  { text: "features", size: 180 },
  { text: "environment", size: 179 },
  { text: "oriented", size: 179 },
  { text: "proficient", size: 178 },
  { text: "cloud", size: 178 },
  { text: "communication skills", size: 176 },
  { text: "problem solving", size: 175 },
];

const MiscKeywords = InitialMiscKeywords.slice(0, 40);

const AllCPPKeywords = {
  TechKeywords,
  ConceptKeywords,
  SoftSkillsKeywords,
  MiscKeywords,
};

export default AllCPPKeywords;
