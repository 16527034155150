var InitialTechKeywords = [
  { text: "aws", size: 534 },
  { text: "react", size: 480 },
  { text: "mongodb", size: 442 },
  { text: "java", size: 264 },
  { text: "sql", size: 262 },
  { text: "mysql", size: 259 },
  { text: "git", size: 256 },
  { text: "rest", size: 255 },
  { text: "python", size: 222 },
  { text: "nosql", size: 221 },
  { text: "docker", size: 197 },
  { text: "angular", size: 195 },
  { text: "azure", size: 176 },
  { text: "react js", size: 149 },
  { text: "ci", size: 149 },
  { text: "kubernetes", size: 147 },
  { text: "cd", size: 139 },
  { text: "redis", size: 137 },
  { text: "ci cd", size: 134 },
  { text: "kafka", size: 106 },
  { text: "gcp", size: 105 },
  { text: "object", size: 101 },
  { text: "object oriented", size: 89 },
  { text: "aws azure", size: 88 },
  { text: "rest apis", size: 87 },
  { text: "nosql databases", size: 85 },
  { text: "php", size: 81 },
  { text: "jquery", size: 81 },
  { text: "docker kubernetes", size: 76 },
  { text: "rest api", size: 70 },
  { text: "golang", size: 68 },
  { text: "monitoring", size: 66 },
  { text: "js react", size: 66 },
  { text: "sql nosql", size: 63 },
  { text: "postgres", size: 61 },
  { text: "spring", size: 60 },
  { text: "mysql postgresql", size: 56 },
  { text: "mysql mongodb", size: 56 },
  { text: "lambda", size: 55 },
  { text: "experience aws", size: 53 },
  { text: "react native", size: 50 },
  { text: "elasticsearch", size: 49 },
  { text: "tools git", size: 44 },
  { text: "platforms aws", size: 43 },
  { text: "aws cloud", size: 43 },
  { text: "xml", size: 42 },
  { text: "aws gcp", size: 41 },
  { text: "object oriented programming", size: 41 },
  { text: "cassandra", size: 41 },
  { text: "using react", size: 40 },
  { text: "django", size: 39 },
  { text: "soap", size: 39 },
  { text: "aws services", size: 38 },
  { text: "frameworks react", size: 38 },
  { text: "react angular", size: 37 },
  { text: "javascript jquery", size: 37 },
  { text: "experience mongodb", size: 36 },
  { text: "sql nosql databases", size: 35 },
  { text: "cd pipelines", size: 32 },
  { text: "ci cd pipelines", size: 32 },
  { text: "databases mysql", size: 31 },
  { text: "applications aws", size: 30 },
  { text: "gcp aws", size: 30 },
  { text: "angular react", size: 30 },
  { text: "js react js", size: 30 },
  { text: "cloud platforms aws", size: 29 },
  { text: "experience react", size: 29 },
  { text: "versioning tools git", size: 29 },
  { text: "code versioning tools git", size: 29 },
  { text: "js mongodb", size: 29 },
  { text: "js python", size: 28 },
  { text: "kafka rabbitmq", size: 28 },
  { text: "mongo", size: 27 },
  { text: "spring boot", size: 26 },
  { text: "databases mongodb", size: 26 },
  { text: "azure gcp", size: 26 },
  { text: "mongodb express", size: 26 },
  { text: "android", size: 25 },
  { text: "oracle", size: 25 },
  { text: "systems git", size: 25 },
  { text: "knowledge aws", size: 25 },
  { text: "g mysql", size: 25 },
  { text: "aws experience", size: 25 },
  { text: "control systems git", size: 24 },
  { text: "version control systems git", size: 24 },
  { text: "e g mysql", size: 24 },
  { text: "mongodb cassandra", size: 24 },
  { text: "nosql mongodb", size: 24 },
  { text: "mysql postgres", size: 23 },
  { text: "sql databases", size: 23 },
  { text: "object oriented design", size: 23 },
  { text: "js angular", size: 23 },
  { text: "aws azure gcp", size: 23 },
  { text: "experience docker", size: 23 },
];

const TechKeywords = InitialTechKeywords.slice(0, 40);

var InitialConceptKeywords = [
  { text: "development", size: 1490 },
  { text: "design", size: 1337 },
  { text: "software", size: 952 },
  { text: "data", size: 690 },
  { text: "develop", size: 536 },
  { text: "services", size: 491 },
  { text: "performance", size: 483 },
  { text: "architecture", size: 477 },
  { text: "practices", size: 473 },
  { text: "cloud", size: 459 },
  { text: "apis", size: 448 },
  { text: "frameworks", size: 436 },
  { text: "testing", size: 426 },
  { text: "requirements", size: 409 },
  { text: "databases", size: 396 },
  { text: "developing", size: 395 },
  { text: "scalable", size: 394 },
  { text: "test", size: 359 },
  { text: "integration", size: 343 },
  { text: "agile", size: 331 },
  { text: "api", size: 331 },
  { text: "database", size: 313 },
  { text: "maintain", size: 306 },
  { text: "software development", size: 289 },
  { text: "related", size: 288 },
  { text: "developers", size: 287 },
  { text: "security", size: 275 },
  { text: "unit", size: 218 },
  { text: "microservices", size: 211 },
  { text: "standards", size: 206 },
  { text: "continuous", size: 204 },
  { text: "patterns", size: 203 },
  { text: "platforms", size: 195 },
  { text: "developer", size: 192 },
  { text: "designing", size: 191 },
  { text: "platform", size: 184 },
  { text: "deployment", size: 182 },
  { text: "infrastructure", size: 177 },
  { text: "experience developing", size: 172 },
  { text: "structures", size: 160 },
  { text: "oriented", size: 154 },
  { text: "design patterns", size: 154 },
  { text: "data structures", size: 153 },
  { text: "algorithms", size: 153 },
  { text: "scalability", size: 150 },
  { text: "tests", size: 150 },
  { text: "development experience", size: 149 },
  { text: "ci", size: 149 },
  { text: "design develop", size: 147 },
  { text: "scale", size: 146 },
  { text: "analytical", size: 144 },
  { text: "troubleshoot", size: 143 },
  { text: "debugging", size: 140 },
  { text: "devops", size: 139 },
  { text: "cd", size: 139 },
  { text: "principles", size: 134 },
  { text: "ci cd", size: 134 },
  { text: "maintainable", size: 133 },
  { text: "related field", size: 132 },
  { text: "concepts", size: 132 },
  { text: "relational", size: 128 },
  { text: "automation", size: 126 },
  { text: "framework", size: 125 },
  { text: "reusable", size: 124 },
  { text: "distributed", size: 122 },
  { text: "methodologies", size: 120 },
  { text: "debug", size: 116 },
  { text: "optimize", size: 112 },
  { text: "web services", size: 112 },
  { text: "maintaining", size: 107 },
  { text: "robust", size: 103 },
  { text: "object", size: 101 },
  { text: "continuous integration", size: 99 },
  { text: "develop maintain", size: 98 },
  { text: "required", size: 98 },
  { text: "high performance", size: 97 },
  { text: "secure", size: 92 },
  { text: "optimization", size: 92 },
  { text: "integrate", size: 92 },
  { text: "designers", size: 91 },
  { text: "software engineering", size: 91 },
  { text: "object oriented", size: 89 },
  { text: "perform", size: 87 },
  { text: "rest apis", size: 87 },
  { text: "reliability", size: 85 },
  { text: "restful apis", size: 85 },
  { text: "nosql databases", size: 85 },
  { text: "driven development", size: 83 },
  { text: "unit testing", size: 83 },
  { text: "design development", size: 81 },
  { text: "native", size: 80 },
  { text: "agile development", size: 80 },
  { text: "structures algorithms", size: 80 },
  { text: "data structures algorithms", size: 80 },
  { text: "experience cloud", size: 79 },
  { text: "end developers", size: 79 },
  { text: "test driven", size: 78 },
  { text: "front end developers", size: 78 },
  { text: "test driven development", size: 77 },
  { text: "fundamentals", size: 77 },
  { text: "web development", size: 74 },
];

const ConceptKeywords = InitialConceptKeywords.slice(0, 40);

var InitialSoftSkillsKeywords = [
  { text: "understanding", size: 783 },
  { text: "engineering", size: 419 },
  { text: "communication", size: 419 },
  { text: "solving", size: 364 },
  { text: "ensure", size: 349 },
  { text: "collaborate", size: 324 },
  { text: "problem solving", size: 311 },
  { text: "maintain", size: 306 },
  { text: "management", size: 291 },
  { text: "write", size: 274 },
  { text: "implement", size: 241 },
  { text: "communication skills", size: 212 },
  { text: "writing", size: 211 },
  { text: "participate", size: 199 },
  { text: "engineers", size: 198 },
  { text: "implementation", size: 168 },
  { text: "solving skills", size: 153 },
  { text: "problem solving skills", size: 152 },
  { text: "manage", size: 149 },
  { text: "ownership", size: 146 },
  { text: "ensuring", size: 141 },
  { text: "deliver", size: 140 },
  { text: "understand", size: 140 },
  { text: "excellent communication", size: 137 },
  { text: "drive", size: 136 },
  { text: "identify", size: 133 },
  { text: "maintainable", size: 133 },
  { text: "mentor", size: 125 },
  { text: "managers", size: 125 },
  { text: "collaboration", size: 118 },
  { text: "learn", size: 113 },
  { text: "implementing", size: 113 },
  { text: "maintaining", size: 107 },
  { text: "learning", size: 105 },
  { text: "write clean", size: 103 },
  { text: "independently", size: 102 },
  { text: "develop maintain", size: 98 },
  { text: "contribute", size: 94 },
  { text: "product managers", size: 91 },
  { text: "software engineering", size: 91 },
  { text: "solve", size: 83 },
  { text: "managing", size: 77 },
  { text: "collaborate cross", size: 76 },
  { text: "collaborate cross functional", size: 74 },
  { text: "delivering", size: 72 },
  { text: "collaborate cross functional teams", size: 71 },
  { text: "design implement", size: 69 },
  { text: "resolve", size: 68 },
  { text: "communicate", size: 66 },
  { text: "mindset", size: 65 },
  { text: "engineer", size: 64 },
  { text: "engineering related", size: 64 },
  { text: "translate", size: 63 },
  { text: "passionate", size: 61 },
  { text: "excellent problem solving", size: 60 },
  { text: "communication collaboration", size: 60 },
  { text: "engineering related field", size: 59 },
  { text: "collaboration skills", size: 59 },
  { text: "solid understanding", size: 58 },
  { text: "mentoring", size: 58 },
  { text: "participate code", size: 57 },
  { text: "collaborating", size: 55 },
  { text: "participate code reviews", size: 55 },
  { text: "communication collaboration skills", size: 54 },
  { text: "design implementation", size: 53 },
  { text: "passion", size: 52 },
  { text: "excellent communication skills", size: 52 },
  { text: "mentor junior", size: 51 },
  { text: "maintainable code", size: 51 },
  { text: "demonstrated", size: 50 },
  { text: "deep understanding", size: 49 },
  { text: "collaborative", size: 48 },
  { text: "work independently", size: 47 },
  { text: "engineering practices", size: 45 },
  { text: "clean maintainable", size: 44 },
  { text: "solving analytical", size: 44 },
  { text: "problem solving analytical", size: 44 },
  { text: "passionate about", size: 44 },
  { text: "take ownership", size: 43 },
  { text: "project management", size: 43 },
  { text: "solving skills ability", size: 43 },
  { text: "problem solving skills ability", size: 43 },
  { text: "solving analytical skills", size: 42 },
  { text: "problem solving analytical skills", size: 42 },
  { text: "write clean maintainable", size: 41 },
  { text: "communication skills ability", size: 41 },
  { text: "proficient understanding", size: 40 },
  { text: "collaborate front", size: 39 },
  { text: "collaborate front end", size: 39 },
  { text: "collaborate front end developers", size: 39 },
  { text: "maintainability", size: 38 },
  { text: "experience writing", size: 37 },
  { text: "science software engineering", size: 36 },
  { text: "computer science software engineering", size: 36 },
  { text: "identifying", size: 36 },
  { text: "management skills", size: 35 },
  { text: "demonstrate", size: 35 },
  { text: "junior engineers", size: 33 },
  { text: "understanding software", size: 33 },
  { text: "excellent problem solving skills", size: 33 },
  { text: "understanding code", size: 33 },
];

const SoftSkillsKeywords = InitialSoftSkillsKeywords.slice(0, 40);

var InitialMiscKeywords = [
  { text: "experience", size: 3586 },
  { text: "knowledge", size: 1102 },
  { text: "js", size: 1101 },
  { text: "code", size: 1087 },
  { text: "skills", size: 949 },
  { text: "team", size: 944 },
  { text: "work", size: 939 },
  { text: "applications", size: 911 },
  { text: "web", size: 840 },
  { text: "years", size: 839 },
  { text: "technologies", size: 818 },
  { text: "end", size: 783 },
  { text: "node", size: 749 },
  { text: "working", size: 681 },
  { text: "node js", size: 677 },
  { text: "using", size: 671 },
  { text: "technical", size: 645 },
  { text: "javascript", size: 628 },
  { text: "product", size: 608 },
  { text: "systems", size: 604 },
  { text: "ability", size: 584 },
  { text: "backend", size: 506 },
  { text: "high", size: 502 },
  { text: "quality", size: 485 },
  { text: "solutions", size: 472 },
  { text: "tools", size: 448 },
  { text: "years experience", size: 424 },
  { text: "front", size: 415 },
  { text: "building", size: 411 },
  { text: "'s", size: 411 },
  { text: "other", size: 405 },
  { text: "excellent", size: 403 },
  { text: "front end", size: 398 },
  { text: "stack", size: 387 },
  { text: "build", size: 379 },
  { text: "programming", size: 371 },
  { text: "application", size: 368 },
  { text: "problem", size: 365 },
  { text: "teams", size: 359 },
  { text: "computer", size: 343 },
  { text: "environment", size: 333 },
  { text: "business", size: 332 },
  { text: "nodejs", size: 327 },
  { text: "css", size: 325 },
  { text: "server", size: 324 },
  { text: "science", size: 320 },
  { text: "experience working", size: 312 },
  { text: "html", size: 310 },
  { text: "computer science", size: 298 },
  { text: "side", size: 296 },
  { text: "user", size: 289 },
  { text: "features", size: 287 },
  { text: "must", size: 282 },
  { text: "e", size: 277 },
  { text: "degree", size: 277 },
  { text: "functional", size: 274 },
  { text: "project", size: 273 },
  { text: "familiarity", size: 265 },
  { text: "including", size: 263 },
  { text: "complex", size: 263 },
  { text: "efficient", size: 263 },
  { text: "hands", size: 261 },
  { text: "proficiency", size: 259 },
  { text: "projects", size: 258 },
  { text: "system", size: 257 },
  { text: "based", size: 257 },
  { text: "plus", size: 255 },
  { text: "server side", size: 251 },
  { text: "html css", size: 247 },
  { text: "support", size: 244 },
  { text: "well", size: 241 },
  { text: "languages", size: 237 },
  { text: "g", size: 234 },
  { text: "web applications", size: 232 },
  { text: "e g", size: 230 },
  { text: "issues", size: 230 },
  { text: "full", size: 229 },
  { text: "products", size: 224 },
  { text: "coding", size: 224 },
  { text: "express", size: 221 },
  { text: "provide", size: 212 },
  { text: "cross", size: 210 },
  { text: "bachelor", size: 209 },
  { text: "typescript", size: 208 },
  { text: "reviews", size: 207 },
  { text: "technology", size: 200 },
  { text: "any", size: 200 },
  { text: "'s degree", size: 199 },
  { text: "problems", size: 197 },
  { text: "degree computer", size: 197 },
  { text: "your", size: 194 },
  { text: "bachelor 's", size: 194 },
  { text: "least", size: 191 },
  { text: "frontend", size: 185 },
  { text: "degree computer science", size: 183 },
  { text: "driven", size: 182 },
  { text: "hands experience", size: 181 },
  { text: "production", size: 178 },
  { text: "expertise", size: 178 },
  { text: "restful", size: 177 },
  { text: "time", size: 176 },
];

const MiscKeywords = InitialMiscKeywords.slice(0, 40);

const AllNodeJSKeywords = { TechKeywords, ConceptKeywords, SoftSkillsKeywords, MiscKeywords }

export default AllNodeJSKeywords;